import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { AuthService } from "src/app/core/services/api/api-auth.service";
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  MatSidenav,
  MatSidenavContainer,
  MatBottomSheet,
} from "@angular/material";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { SideNavEventHandlerService } from "src/app/common/event/sidenav-event-handler.service";
import { ScrollEventService } from "src/app/common/event/scroll-event.service";
import { AppDataService } from "src/app/app-data/app-data.service";
import { DistributorService } from "src/app/core/services/api/distributor.service";
import { AdminService } from "src/app/core/services/api/admin.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { HubService } from "src/app/core/services/api/hub.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-supply",
  templateUrl: "./supply.component.html",
  styleUrls: ["./supply.component.scss"],
})
export class SupplyComponent implements OnInit {
  hubs = [];
  currHub = "";
  currHubId = "";
  newHubId = "";
  showHubSelection = false;
  showTransactions = false;
  showManualCN = false;
  showReturn = false;
  showExpiry = false;
  showLI = false;
  showSO = false;
  showPO = false;
  showPurchase = false;
  showSI = false;
  showExpiryOrders = false;
  _drawerOpened: boolean = false;
  _slideMode = "over";

  _mNavMode = "side";
  _mNavOpen = false;
  _mShowMenu = false;
  auth;
  isAuthenticated: boolean = false;
  panelOpenState = false;
  isExpanded = false;
  isAdmin = false;
  showDetails = false;
  orderedBilledData = [];
  invoiceNumber;

  subscriptions: Subscription[] = [];
  mCurrentHeading = 1;

  isPOBUser = false;
  constructor(
    private authService: AuthService,
    private appDataService: AppDataService,
    private location: Location,
    private router: Router,
    private scrollEventService: ScrollEventService,
    private media: MediaObserver,
    private sideNavEventHandlerService: SideNavEventHandlerService,
    private adminService: AdminService,
    private popupService: PopupService,
    private hubService: HubService,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: any
  ) {
    this.media.media$.subscribe((mediaChange: MediaChange) => {
      this._mNavMode = this.getMode(mediaChange);
      // console.log("sidena3:", this._mNavMode);
      this.updateSlideMode(mediaChange);
      // this.opened = this.getOpened(mediaChange);
      this.router.events.subscribe((val) => {
        if (this.location.path() != "") {
          const routePath = this.location.path();

          return;
        } else {
        }
      });
    });
  }

  @ViewChild("fixedContent", { static: true }) public fixedContent: ElementRef;

  @ViewChild("mainDrawer", { static: true }) sidenav: MatSidenav;

  @ViewChild("sideNavContainer", { static: true })
  myScrollContainer: MatSidenavContainer;

  @ViewChild("contentScroller", { static: true }) contentScroller: ElementRef;

  @Output() public sidenavToggle = new EventEmitter();
  @Output() sidenavClose = new EventEmitter();

  toggleReturn() {
    this.showReturn = !this.showReturn;
  }

  toggleTransactions() {
    this.showTransactions = !this.showTransactions;
  }

  toggleManualCN() {
    this.showManualCN = !this.showManualCN;
  }

  toggleLogisticItems() {
    this.showLI = !this.showLI;
  }

  toggleSalesOrder() {
    this.showSO = !this.showSO;
  }

  togglePurchaseOrder() {
    this.showPO = !this.showPO;
  }

  togglePurchase() {
    this.showPurchase = !this.showPurchase;
  }

  toggleSI() {
    this.showSI = !this.showSI;
  }

  toggleExpiry() {
    this.showExpiry = !this.showExpiry;
  }

  toggleExpiryOrder() {
    this.showExpiryOrders = !this.showExpiryOrders;
  }

  logout() {
    this.authService.logout().subscribe(
      (value) => {
        // console.log("logout: ", value);
        this.router.navigate(["/authenticate"]);
        this.appDataService.setData("IsUserExist", "false", "local");
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  ngOnInit() {
    this.getHubs();
    if (
      this.router.url.includes("buyer-logistics-item") ||
      this.router.url.includes("wrong-missing-items") ||
      this.router.url.includes("items-from-decision-room") ||
      this.router.url.includes("items-from-expiry")
    ) {
      this.showLI = true;
      this.showReturn = true;
    } else if (
      this.router.url.includes("live-sales-order") ||
      this.router.url.includes("past-sales-order") ||  this.router.url.includes("liveso-retrieval-split")
    ) {
      this.showSO = true;
      this.showTransactions = true;
    } else if (
      this.router.url.includes("auto-purchase-order") ||
      this.router.url.includes("past-purchase-order")
    ) {
      this.showPO = true;
      this.showTransactions = true;
    } else if (
      this.router.url.includes("advanced-delivery-note") ||
      this.router.url.includes("good-receive-note") ||
      this.router.url.includes("past-purchase-import")
    ) {
      this.showPurchase = true;
      this.showTransactions = true;
    } else if (
      this.router.url.includes("live-sales-invoice") ||
      this.router.url.includes("past-sales-invoice") ||
      this.router.url.includes("print-selected-bill")
    ) {
      this.showSI = true;
      this.showTransactions = true;
    } else if (
      this.router.url.includes("generate-dn") ||
      this.router.url.includes("dn-pending") ||
      this.router.url.includes("dn-settled") ||
      this.router.url.includes("manual-dn") ||
      this.router.url.includes("credit-note") ||
      this.router.url.includes("all-credit-note")
    ) {
      this.showReturn = true;
    } else if (
      this.router.url.includes("live-expiry-orders") ||
      this.router.url.includes("past-expiry-orders")
    ) {
      this.showExpiry = true;
      this.showExpiryOrders = true;
    } else {
      this.showManualCN = true;
    }
    this.authService.getUserPhoneNumber().subscribe((num) => {
      const phone = num;
      this.authService.subscribeUserData(phone).subscribe((response) => {
        this.isAdmin = response.data.user.admin;

        response.data.user.roles.forEach((ele) => {
          if (ele.role === "POB_SILVERCROSS") {
            this.isPOBUser = true;
          }
        });
      });
    });
    this.subscriptions[this.subscriptions.length] = this.scrollEventService
      .getActionMainEmitter()
      .subscribe((heading: Number) => {
        this.mCurrentHeading = heading as number;
        this.scrollToHeader(heading);
      });
  }

  navigateTo(heading: Number) {
    this.scrollEventService.emitHeadingEvent(heading);
    // this.closeDrawer()
  }

  scrollToHeader(heading: Number) {
    const headerClass: String = this.getHeaderClass(heading);
  }

  getHeaderClass(heading: Number): String {
    // switch (heading) {
    //   case ScrollEventService.SUB_HEADING.home:
    //     return '#home'

    //   default:
    //     break;
    // }
    return "";
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private updateSlideMode(mediaChange: MediaChange) {
    if (this.media.isActive("lt-md")) {
      this._slideMode = "over";
      this._drawerOpened = false;
    } else {
      this._slideMode = "over";
      this._drawerOpened = false;
    }
  }

  onActionLaunch(event) {
    this._mNavOpen = true;
    const isOpened = this.sidenav.opened;
    this.sidenav.toggle();
    this.sideNavEventHandlerService.emitMainEvent(!isOpened);
    // console.log(event);
  }

  onActivate(e, outlet) {
    this.contentScroller.nativeElement.scrollTop = 0;
    // this.myScrollContainer.scrollable.getElementRef().nativeElement.scrollTop = 0;
  }

  getMode(mediaChange: MediaChange): string {
    if (this.media.isActive("lt-md")) {
      // this._mNavOpen = false;
      return "";
    } else {
      // this._mNavOpen = false;
      return "side";
    }
  }

  _backdropToggle(type) {
    if (type === "backdrop") {
      this._drawerOpened = !this._drawerOpened;
    }
  }

  _toggleSideNav(sideNav) {
    // console.log(sideNav.opened);
    // if (sideNav.opened == false) {
    this._drawerOpened = !this._drawerOpened;
    // }
  }

  openSideNav(invNo) {
    this.popupService.openProgress();
    const params = {
      invoiceNumber: invNo,
    };
    this.invoiceNumber = invNo;
    this.adminService.globalSearchGetOrderedBilledData(params).subscribe(
      (response) => {
        this.orderedBilledData = response.data;
        this.orderedBilledData.map((item) => {
          if (!item.orderCompareDetails) {
            item.orderCompareDetails = {};
          }
          if (!item.purchaseCompareDetails) {
            item.purchaseCompareDetails = {};
          }
          if (!item.billCompareDetails) {
            item.billCompareDetails = {};
          }
        });
        this.showDetails = true;
        this.popupService.closeProgress();
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  discard() {
    this.showDetails = false;
  }

  openGeneratePO() {
    this.popupService
      .openGeneratePO()
      .afterClosed()
      .subscribe((response) => {});
  }

  getHubs() {
    this.hubService.getHubs().subscribe(
      (response) => {
        this.currHubId = response.headers.getAll("hubId");
        this.newHubId = response.headers.getAll("hubId");
        localStorage.setItem("hubId", this.newHubId);
        this.hubs = response.body.data;
        this.hubs.map((hub) => {
          if (hub.id == this.currHubId) {
            this.currHub = hub.hubCode;
          }
        });
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  openHubSelection() {
    this.showHubSelection = true;
  }

  updateHub() {
    this.popupService.openProgress();
    const body = {
      hubId: this.newHubId,
    };
    this.hubService.updateHub({}, body).subscribe(
      (response) => {
        localStorage.setItem("hubId", body.hubId);
        this.currHubId = this.newHubId;
        this.hubs.map((hub) => {
          if (hub.id == this.currHubId) {
            this.currHub = hub.hubCode;
          }
        });
        this.showHubSelection = false;
        this.popupService.closeProgress();
        localStorage.removeItem("generateCNData");
        this.router.navigate(["/supply"]);
        // location.reload();
      },
      (error) => {
        this.showHubSelection = false;
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  checkHub() {
    var checkHubCode, checkHubId, currHubId;
    checkHubId = JSON.parse(localStorage.getItem("hubId"));
    this.hubs.map((hub) => {
      if (hub.id == checkHubId) {
        checkHubCode = hub.hubCode;
      }
      if (hub.hubCode == this.currHub) {
        currHubId = hub.id;
      }
    });
    if (checkHubCode && checkHubCode != this.currHub) {
      localStorage.setItem("hubId", currHubId);
      location.reload();
    }
  }
}
