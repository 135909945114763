import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ApiService } from "../../../common/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class SupplyService {
  API_URL: string = environment.adminUrl + "/admin";
  API_URL_V2: string = environment.adminUrl + "/v2/admin";
  preprod_url = "https://preprod.saveo.in/admin";
  expiryUrl = environment.adminUrl + "/expiry/admin";
  eagleUrl = environment.eagleUrl;
  billUrl = environment.billUrl;
  selectedOrderIdsToPrint = [];

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getAllDistributorInfo(): Observable<any> {
    const urlPath = this.API_URL + "/getAllDistributorInfo";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllDistributorsForGeneratePO(): Observable<any> {
    const urlPath = this.API_URL_V2 + "/generatePO/getDistributors";
    return this.apiService.doGetObservable(urlPath);
  }

  getBuyerNotRequiredInvoices(params): Observable<any> {
    const urlPath = this.API_URL + "/supply/returnItems/notRequired";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBuyerNotRequiredInvoiceDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/supply/returnItems/notRequired/getItems";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  sendForDebitNote(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/returnItems/sendForDN";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  setDamaged(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/returnItems/setDamaged";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  sendToInventory(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/returnItems/sendItemsToInventory";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getWrongMissingItemInvoices(): Observable<any> {
    const urlPath = this.API_URL + "/supply/returnItems/wrongAndMissing";
    return this.apiService.doGetObservable(urlPath);
  }

  getWrongMissingItems(params): Observable<any> {
    const urlPath =
      this.API_URL + "/supply/returnItems/wrongAndMissing/getItems";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  setNotFound(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/returnItems/setNotFound";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  solveNearExpiry(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/returnItems/solveNearExpiry";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getWronglyMappedMedicine(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/getWronglyMappedMedicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  setWronglyMappedMedicine(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/setWronglyMappedMedicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getItemsForDN(params): Observable<any> {
    const urlPath = this.API_URL + "/supply/generateDN/getItemsForDN";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  generateDebitNote(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/generateDN/createDebitNote";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getPendingDebitNotes(params, isExpiry): Observable<any> {
    const urlPath =
      this.API_URL +
      (isExpiry
        ? "/expiry/supply/dnPending/getPendingDebitNotes"
        : "/supply/dnPending/getPendingDebitNotes");
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getUnsettledItems(params, isExpiry): Observable<any> {
    const urlPath =
      this.API_URL +
      (isExpiry
        ? "/expiry/supply/dnPending/getUnsettledItems"
        : "/supply/dnPending/getUnsettledItems");
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPossibleStates(): Observable<any> {
    const urlPath = this.API_URL + "/supply/dnPending/getPossibleStates";
    return this.apiService.doGetObservable(urlPath);
  }

  updateState(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/dnPending/updateState";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getDebitNoteDetailsPage(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/getDebitNoteDetailsPage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  setDistributorCreditNote(params, body, isExpiry): Observable<any> {
    const urlPath =
      this.API_URL +
      (isExpiry
        ? "/expiry/supply/setDistributorCreditNote"
        : "/supply/setDistributorCreditNote");
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  dnPendingSetDamaged(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/dnPending/setDamaged";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  dnPendingAddToInventory(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/dnPending/addToInventory";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  dnPendingSetDistributorCreditNote(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/setDistributorCreditNote";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getDistributorCreditNotes(params): Observable<any> {
    const urlPath = this.API_URL + "/supply/getDistributorCreditNotes";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateDistirbutorCreditNote(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/editDistributorCreditNote";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateDebitNoteItem(params, body): Observable<any> {
    const urlPath = this.API_URL + "/returns/updateDebitNoteDetails";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  deleteDebitNoteItem(params, body): Observable<any> {
    const urlPath = this.API_URL + "/returns/deleteDebitNoteMedicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getLiveSalesOrders(): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/liveSO/home";
    return this.apiService.doGetObservable(urlPath);
  }

  getLiveSalesOrdersFranchise(): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/liveSO/home/retailers";
    return this.apiService.doGetObservable(urlPath);
  }

  getViewOrders(params): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/liveSO/viewOrders";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  viewOrdersOfRetailer(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/liveSO/viewOrdersOfRetailer";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  cancelOrders(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/liveSO/cancelOrders";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getPastSalesOrders(params): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/pastSO/home";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getPastViewOrders(params): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/pastSO/viewOrders";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  PastViewOrdersOfRetailer(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/pastSO/viewOrdersOfRetailer";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  PastCancelOrders(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/liveSO/cancelOrders";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  retrieveOrder(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/liveSO/retrieveAndAutoClub";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  retrieveOrderFranchise(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/liveSO/retrieveAndAutoClubSplitRetailers";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  retrieveMultipleOrder(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/liveSO/retrieveAllAndAutoClub";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  retrieveSelectedSpokeSlots(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/liveSO/retrieveAllByAccountTypeAndClub";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  retrieveBack(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/pastSO/undoRetrieve";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getLivePurchaseOrders(params,body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/livePO/home";
    return this.apiService.doPostObservableForQuery(urlPath,body,params);
  }

  sendLivePO(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/livePO/sendClubOrders";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  downloadLivePO(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/livePO/downloadClubOrder";
    return this.apiService.doPostObservableForCSV(urlPath, body, params);
  }

  getPastPurchaseOrders(params,body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/pastPO/home";
    return this.apiService.doPostObservableForQuery(urlPath,body, params);
  }

  editLiveSalesOrder(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/liveSO/editOrder";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  searchDistributor(params): Observable<any> {
    const urlPath = this.API_URL_V2 + "";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  searchDistributorMedicines(params): Observable<any> {
    const urlPath = this.API_URL_V2 + "";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  calulateNetValue(params): Observable<any> {
    const urlPath = this.API_URL_V2 + "";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  editLivePurchaseOrder(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/livePO/editClubOrder";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  downloadPastPO(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/downloadClubOrder";
    return this.apiService.doGetObservableForCSV(urlPath, params);
  }

  getADNlivePO(params,body): Observable<any> {
    const urlPath = this.API_URL + "/ADN/getAllLivePurchaseOrder";
    return this.apiService.doPostObservableForQuery(urlPath,body, params);
  }

  searchMedicineForPO(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/wholesale/generatePO/searchAllMedicine";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  calculatNetValueForPO(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/wholesale/generatePO/addMedicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  generateAndDownloadPO(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/generatePO/createPOAndDownload/V2";
    return this.apiService.doPostObservableForCSV(urlPath, body, params);
  }

  getMultipleADNDetail(params): Observable<any> {
    const urlPath = this.API_URL + "/ADN/viewADNDetailByClubOrderIds";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  deleteUploadADN(params): Observable<any> {
    const urlPath = this.API_URL + "/ADN/deleteADN";
    return this.apiService.doDeleteForQuery(urlPath, params);
  }

  adnSplitByClubOrderIds(params, body): Observable<any> {
    const urlPath = this.API_URL + "/ADN/doSplitByClubOrderIds";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  adnSplitByClubOrderIdsTest(params, body): Observable<any> {
    const urlPath = this.API_URL + "/ADN/v2/doSplitByClubOrderIds";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getCountOfUnprocessedADN(params): Observable<any> {
    const urlPath = this.API_URL + "/ADN/getCountOfUnProcessedADN";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllADNForPI(params,body): Observable<any> {
    const urlPath = this.API_URL + "/ADN/getAllADNForPI";
    return this.apiService.doPostObservableForQuery(urlPath,body, params);
  }

  adnRetrySplit(params, body): Observable<any> {
    const urlPath = this.API_URL + "/ADN/doSplit";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  adnRetrySplitTest(params, body): Observable<any> {
    const urlPath = this.API_URL + "/ADN/v2/doSplit";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  getADNDetailByid(params): Observable<any> {
    const urlPath = this.API_URL + "/ADN/viewADNDetailById";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllPOForGRN(params): Observable<any> {
    const urlPath = this.API_URL + "/GRN/getAllPoForGRN";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  downloadGRN(params): Observable<any> {
    const urlPath = this.API_URL + "/GRN/downloadById";
    return this.apiService.doGetObservableForCSV(urlPath, params);
  }

  downloadPastPI(params): Observable<any> {
    const urlPath = this.API_URL + "/ADN/downloadById";
    return this.apiService.doGetObservableForCSV(urlPath, params);
  }

  getUploadedFileStatus(params): Observable<any> {
    const urlPath = this.API_URL + "/ADN/getUploadedFileStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllDistributorsInfo(params): Observable<any> {
    const urlPath =
      this.API_URL + "/supply/salesInvoice/getDistributors/byFilter";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllDestinations(): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/getAllRetrievalSpokeCode";
    return this.apiService.doGetObservable(urlPath);
  }

  getDistances(params): Observable<any> {
    const urlPath = environment.adminUrl + "/home/getDistances";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBuyerInvoiceByFilter(params): Observable<any> {
    const urlPath = this.API_URL + "/getPastBuyerInvoice/byFilters";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBuyerInvoiceByPage(params): Observable<any> {
    const urlPath = this.API_URL + "/getPastBuyerInvoice/byFilters/pagewise";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBuyerInvoiceStats(params): Observable<any> {
    const urlPath = this.API_URL + "/getPastBuyerInvoice/stats";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getBuyerInvoiceDetail(params): Observable<any> {
    const urlPath = this.API_URL + "/invoice/getBuyerInvoiceDetailWithPage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateBill(data): Observable<any> {
    const urlPath = this.API_URL + "/invoice/updateBuyerInvoiceDetails";
    return this.apiService.doUpdate(urlPath, data);
  }
  updateBillTest(data): Observable<any> {
    const urlPath = this.API_URL + "/invoice/v2/updateBuyerInvoiceDetails";
    return this.apiService.doUpdate(urlPath, data);
  }

  requestMedFromSaveoInv(params, body): Observable<any> {
    const urlPath = this.API_URL + "/billing/requestMedicineFromSI";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  deleteMedicineFromBill(params, body): Observable<any> {
    const urlPath = this.API_URL + "/invoice/deleteBuyerInvoiceMedicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  searchMedicine(params): Observable<any> {
    const urlPath = this.API_URL + "/search/v2/all/medicine";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  searchMedicineTest(params): Observable<any> {
    const urlPath = this.API_URL + "/search/v3/all/medicine";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  searchMedicineSaveoInv(params): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/request/search/name";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  checkBuyerInvoiceStatus(params): Observable<any> {
    const urlPath = this.API_URL + "/checkBuyerInvoiceStatus/byFilters";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllBillsByFilters(params): Observable<any> {
    const urlPath = this.API_URL + "/getPastBuyerInvoice/byFilters";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getMedsByKey(params): Observable<any> {
    const urlPath = this.API_URL + "/ADN/searchMedByKey";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateMapMedicine(params): Observable<any> {
    const urlPath = this.API_URL + "/ADN/updateADNMedDetails";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }
  updateMapMedicinev2(params): Observable<any> {
    const urlPath = this.API_URL + "/ADN/updateADNMedDetails/v2";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  getAllSlotsByDate(params): Observable<any> {
    const urlPath = this.API_URL + "/getdeliverySlots/bydate";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getReturnOptions(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/returnItems/getReturnOptions";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  deleteMedsLiveSo(params, body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/liveSO/deleteMedicineInOrder";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  searchAllGeneratePoMedicines(params): Observable<any> {
    const urlPath = this.API_URL_V2 + "/generatePO/searchAllMedicine";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  addNewGeneratePoMedicine(params, body): Observable<any> {
    const urlPath = this.API_URL_V2 + "/generatePO/addNewMedicine";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  searchManualDnMedicines(params): Observable<any> {
    const urlPath = this.API_URL + "/manualDN/getMedicineSuggestionWithBatch";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  generateManualDn(params, body): Observable<any> {
    const urlPath = this.API_URL + "/manualDN/generateDN";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getManualDnReasons(): Observable<any> {
    const urlPath = this.API_URL + "/manualDN/getItemReturnReasons";
    return this.apiService.doGetObservable(urlPath);
  }

  getOrderTypeOptions(): Observable<any> {
    const urlPath = this.API_URL + "/buyerInvoice/getOrderTypeOptions";
    return this.apiService.doGetObservable(urlPath);
  }

  editOrderType(params, body): Observable<any> {
    const urlPath = this.API_URL + "/buyerInvoice/editOrderType";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getAdnUpdateReasons(): Observable<any> {
    const urlPath = this.API_URL + "/getReason/updateInvoiceValue";
    return this.apiService.doGetObservable(urlPath);
  }

  getBillUpdateReasons(params): Observable<any> {
    const urlPath = this.API_URL + "/getReasons/forBillModification";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateInvoiceValue(params, body): Observable<any> {
    const urlPath = this.API_URL + "/ADN/updateInvoiceValueWithReason";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getLiveExpiryRoutes(): Observable<any> {
    const urlPath = this.expiryUrl + "/liveOrders/home";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllBuyerInvoices(params): Observable<any> {
    const urlPath = this.API_URL + "/getBuyerInvoiceIds/byFilters";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getLiveExpiryRouteOrders(params): Observable<any> {
    const urlPath = this.expiryUrl + "/liveOrders/viewOrdersOfRoute";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAssignee(): Observable<any> {
    const urlPath = this.expiryUrl + "/getAssignee";
    return this.apiService.doGetObservable(urlPath);
  }

  assignAllOrdersOfRoute(params, body): Observable<any> {
    const urlPath = this.expiryUrl + "/assignAllOrdersOfRoute";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  assignExpiryOrders(params, body): Observable<any> {
    const urlPath = this.expiryUrl + "/assignOrders";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getPastExpiryOrders(params): Observable<any> {
    const urlPath = this.expiryUrl + "/pastOrders/home";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  assignPastExpiryOrders(params, body): Observable<any> {
    const urlPath = this.expiryUrl + "/assignPastOrders";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getPrintExpiryOrderData(params, body): Observable<any> {
    const urlPath = this.expiryUrl + "/liveOrders/viewPrintForm";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getVendorDelaySymbols(): Observable<any> {
    const urlPath = this.API_URL + "/getVendorDelaySymbols";
    return this.apiService.doGetObservable(urlPath);
  }

  mapVendorSymbols(params, body): Observable<any> {
    const urlPath = this.API_URL + "/updateDelaySymbolsBill";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getExpiryActiveCreditNote(): Observable<any> {
    const urlPath = this.API_URL + "/expiry/creditNote/viewActive";
    return this.apiService.doGetObservable(urlPath);
  }

  getExpiryFianlizedCreditNote(): Observable<any> {
    const urlPath = this.API_URL + "/expiry/creditNote/viewFinalized";
    return this.apiService.doGetObservable(urlPath);
  }

  getExpiryCreditNoteDetails(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/expiry/creditNote/getDetailsPage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  finalizeExpiryCreditNote(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/expiry/creditNote/finalize";
    return this.apiService.doPostObservableForQuery(urlPath, {}, params);
  }

  getItemsFromDecisionRoom(): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/debitNote/intermediate/items/all";
    return this.apiService.doGetObservable(urlPath);
  }

  updateItemsFromDecisionRoomRemarks(params): Observable<any> {
    const urlPath =
      this.API_URL + "/logistics/debitNote/intermediate/items/update/remarks";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  createDebitNoteForItemsFromDecisionRoom(params, body) {
    const urlPath =
      this.API_URL + "/logistics/debitNote/createFromDecisionRoom";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateItemsFromDecisionRoomFoundQuantity(params): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/admin/logistics/debitNote/intermediate/items/update/foundQuantity";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSchemeBillModification(params): Observable<any> {
    const urlPath =
      this.API_URL + "/billing/getAvailableSchemeInBillModification";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  deleteRequestMedicine(params, body): Observable<any> {
    const urlPath = this.API_URL + "/billing/removeRequestedMedicineSI";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  getManualBillType(params): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/billType/manualBilling";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getManualBusinessTypes(params): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/businessType/manualBilling";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  deleteManualPo(params): Observable<any> {
    const urlPath = environment.adminUrl + "/v2/admin/wholesale/deleteManualPO";
    return this.apiService.doDeleteForQuery(urlPath, params);
  }

  getCsvFormatForAdn(params): Observable<any> {
    const urlPath = this.API_URL + "/getSampleCSVHeadersForADN/wholesale";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getReturnOptionsV2(params, body): Observable<any> {
    const urlPath = this.API_URL + "/supply/returnItems/getReturnOptionsV2";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getConvertPoToAdn(params): Observable<any> {
    const urlPath = this.API_URL + "/getConvertPoToADN/wholesale";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  downloadPI(params): Observable<any> {
    const urlPath = this.API_URL + "/PI/downloadPI";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  deletePI(params, body): Observable<any> {
    const urlPath = this.API_URL + "/PI/deletePI";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getUploadedGRN(params): Observable<any> {
    const urlPath = this.API_URL + "/GRN/getUploadedGRNFileStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  deleteUploadGRN(params): Observable<any> {
    const urlPath = this.API_URL + "/GRN/DeleteGRN";
    return this.apiService.doDeleteForQuery(urlPath, params);
  }

  getDistributorsByServicingHub(params): Observable<any> {
    const urlPath =
      environment.hubUrl + "/distributor/home/findByServicingHubId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getItemsFromExpiry(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/expiry/supply/getItemsFromExpiry";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  sendExpiryItemsForDebitNote(params, body): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/expiry/supply/sendForDN";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getExpiryReturnOptions(params, body): Observable<any> {
    const urlPath = this.API_URL + "/expiry/supply/getReturnOptions";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  sendExpiryItemsForDn(params, body): Observable<any> {
    const urlPath = this.API_URL + "/expiry/supply/sendForDN";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  generateExpiryDebitNote(params, body): Observable<any> {
    const urlPath = this.API_URL + "/expiry/supply/createDN";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getExpiryDnDetails(params): Observable<any> {
    const urlPath = this.API_URL + "/expiry/supply/dnPending/getDetailsPage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  generateIRN(params): Observable<any> {
    const urlPath = this.API_URL + "/billing/generateIRN";
    return this.apiService.doPostObservableForQuery(urlPath, {}, params);
  }

  generateIRNV2(params): Observable<any> {
    const urlPath = this.API_URL + "/eivoice/generateIRN";
    return this.apiService.doPostObservableForQuery(urlPath, {}, params);
  }

  generateEwayBill(body): Observable<any> {
    const urlPath = this.API_URL + "/eivoice/generateEwayBill";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  getAllCreditNoteForIRN(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/getAllCreditNotesForIRN";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getCNDetailsForLogisticsReturn(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/getCreditNoteDetailsPage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getCNDetailsForRDCN(params): Observable<any> {
    const urlPath = this.API_URL + "/outstanding/viewRdCNPage";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  generateIRNForCreditNote(params): Observable<any> {
    const urlPath = this.API_URL + "/billing/generateIRNForCNByFilters";
    return this.apiService.doPostObservableForQuery(urlPath, {}, params);
  }

  generateBulkIRN(params, body): Observable<any> {
    const urlPath = this.API_URL + "/billing/generateIRN/byFilters/pagewise";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  generateIRNByFilters(params, body): Observable<any> {
    const urlPath = this.API_URL + "/billing/v1/generateIRN/byFilters";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getManualPoSplitFields(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/v2/admin/manualPO/splitQuantity/feilds";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  wholeBillRequestFileFormat() {
    const urlPath = this.API_URL + "/invoice/bulkBillRequestFileFormat";
    return this.apiService.doGetObservableForQuery(urlPath, {});
  }

  getSearchMedicine(params) {
    const urlPath = this.API_URL + "/logistics/manualCN/search/medicine";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSearchMedicineMatch(params) {
    const urlPath = this.API_URL + "/logistics/manualCN/search/medicineMatch";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  generateManualCN(body) {
    const urlPath = this.API_URL + "/logistics/manualCN/generateManualCN";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  getManualCNsByCreationId(params) {
    const urlPath =
      this.API_URL + "/logistics/manualCN/getManualCNByCreationId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllManualCNs(params) {
    const urlPath = this.API_URL + "/logistics/manualCN/viewAll";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  approveManualCNs(body) {
    const urlPath = this.API_URL + "/logistics/manualCN/approve";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  updateDiscountManualCN(body) {
    const urlPath = this.API_URL + "/logistics/manualCN/updateDiscount";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  revertCreditNote(params): Observable<any> {
    const urlPath = this.API_URL + "/returns/revertCreditNote";
    return this.apiService.doPostObservableForQuery(urlPath, {}, params);
  }

  getPDFBill(body): Observable<any> {
    const urlPath = this.billUrl + "/pdfBills";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  getIRNStatus(lstInvoiceNumbers, params): Observable<any> {
    const urlPath = this.API_URL + "/billing/getIRNStatus";
    return this.apiService.doPostObservableForQuery(
      urlPath,
      lstInvoiceNumbers,
      params
    );
  }

  getAllAdnFromPO(params) {
    const urlPath = this.API_URL + "/getADNsFromPOs";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllAdnFromPOByAdn(params) {
    const urlPath = this.API_URL + "/search/AdnDate/AdnNumber";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  getAllAdnFromPOByPo(params) {
    const urlPath = this.API_URL + "/search/PoDate/PoNumber";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getCNRetailerData(params) {
    const urlPath =
      this.API_URL + "/logistics/manualCN/getUserByRetailerCodeAndHub";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllInvoicesForCoinsRDCN(params) {
    const urlPath = this.API_URL + "/rdcn/coins/viewInvoices";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  createRDCNForCoins(params): Observable<any> {
    const urlPath = this.API_URL + "/rdcn/coins/create";
    return this.apiService.doPostObservableForQuery(urlPath, {}, params);
  }

  getNetValueForCN(body) {
    const urlPath = this.API_URL + "/logistics/manualCN/getNetValue";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  // Distributor Limit APIs
  totalAvailableVendorLimit() {
    const urlPath = this.API_URL + "/expiry/vendor/totalAvailableLimit";
    return this.apiService.doGetObservable(urlPath);
  }

  getVendorLimitData(params) {
    const urlPath = this.API_URL + "/expiry/vendor/view";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateVendorLimitData(body) {
    const urlPath = this.API_URL + "/expiry/vendor/update";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  addNewVendorData(body) {
    const urlPath = this.API_URL + "/expiry/vendor/add";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  // Retailer Limit APIs
  totalAvailableRETLimit() {
    const urlPath = this.API_URL + "/expiry/retailer/totalAvailableLimit";
    return this.apiService.doGetObservable(urlPath);
  }

  getRETLimitData(params) {
    const urlPath = this.API_URL + "/expiry/retailer/view";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateRETLimitData(body) {
    const urlPath = this.API_URL + "/expiry/retailer/update";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  addNewRETData(body) {
    const urlPath = this.API_URL + "/expiry/retailer/add";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  retrieveLiveExpiryOrders(params, body): Observable<any> {
    const urlPath = this.expiryUrl + "/retrieveLiveOrders";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getExpiryLiveOrders(params) {
    const urlPath = environment.adminUrl + "/expiry/admin/liveOrders/home/v2";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getExpiryLiveOrderItems(params) {
    const urlPath =
      environment.adminUrl + "/expiry/admin/liveOrders/viewOrderItems";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSamplePOBFile(): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/pob/getPOBDistributorSampleFileColumns";
    return this.apiService.doGetObservableForQuery(urlPath, {});
  }

  uploadPOBDistributor(file: any): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/pob/uploadPOBDistributor";
    return this.http.post<any>(urlPath, file);
  }
  updateFreezeStatus(body): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/billing/freezeUnfreezeBI";
    return this.apiService.doPostObservableForQuery(urlPath, body, {});
  }

  getWholesaleInvMeds(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/saveoinventory/wholesaleRequest/search";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  openWsServer(): Observable<any> {
    const urlPath =
      environment.eagleUrl + "/sockets/openSocket";
    return this.apiService.doPostObservableForQuery(urlPath, {},{});
  }
  getBillByAdnInvNo(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/skites/getBuyerInvoiceDetailWithAdnInvoiceNumber";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  autoADN(): Observable<any> {
    const urlPath =
    environment.adminUrl + "/admin/skites/adnAndSplit";
    return this.apiService.doPostObservableForQuery(urlPath, {},{});
  }

  packedStatusUpdateForSkites(body): Observable<any> {
    const urlPath =
    environment.eagleUrl + "/rt4";
    return this.apiService.doPostObservableForQuery(urlPath, body,{});
  }

  boxPackScanUpdate(body): Observable<any> {
    const urlPath =
    environment.adminUrl  + "/v2/admin/orderBagScan";
    return this.apiService.doPostObservableForQuery(urlPath, body,{});
  }

  getBillForBagPackages(params,body): Observable<any> {
    const urlPath =
      environment.adminUrl + "/home/sorterpacker/printOrders";
    return this.apiService.doPostObservableForQuery(urlPath,body, params);
  }

  updateRackBin(body): Observable<any> {
    const urlPath =
    environment.adminUrl  + "/v2/admin/updateRackBin";
    return this.apiService.doPostObservableForQuery(urlPath, body,{});
  }
  
  getFranchiseEnabledRSP(params): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/ADN/doSplitFranchiseEnabled";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
}
